<template lang="pug">
	.d-flex.align-items-center.discount-timer(v-if='remainingTime')
		img.discount-timer__img(:src="'/assets/img/icons/alarm-clock.svg'" v-if='remainingTime.days == 0')
		img.discount-timer__img(:src="'/assets/img/icons/light-calendar.svg'" v-else)
		span.discount-timer__txt(v-if='remainingTime.days == 0') {{ remainingTime.hours }}:{{ remainingTime.minutes }}:{{ remainingTime.seconds }}
		span.discount-timer__txt(v-else) {{ $t('forms.until') }} {{ targetDate.format('DD.MM.YYYY') }}
</template>

<script>
import moment from 'moment-timezone';

export default {
    props: {
        until: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            countdownInterval: null,
            remainingTime: null,
        };
    },
    mounted() {
        this.startCountdown();
    },
    computed: {
        targetDate() {
            if (this.until == '') return null;

            return moment(this.until).add(-3, 'hours');
        },
    },
    methods: {
        startCountdown() {
            this.countdownInterval = setInterval(() => {
                const difference = this.targetDate.diff(moment(new Date().getTime()));

                if (difference > 0) {
                    this.remainingTime = this.calculateTime(difference);
                } else {
                    this.remainingTime = null;
                    clearInterval(this.countdownInterval);
                    this.done();
                }
            }, 1000);
        },
        calculateTime(timeDifference) {
            const seconds = Math.floor((timeDifference / 1000) % 60);
            const minutes = Math.floor((timeDifference / 1000 / 60) % 60);
            const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
            const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

            return {
                days,
                hours: hours < 10 ? '0' + hours : hours,
                minutes: minutes < 10 ? '0' + minutes : minutes,
                seconds: seconds < 10 ? '0' + seconds : seconds,
            };
        },
        done() {
            this.$emit('doneTimer', true);
        },
    },
};
</script>

<style lang="scss" scoped>
.discount-timer {
    min-width: 104px;

    @media (min-width: 576px) {
        min-width: 78px;
    }

    &__txt {
        display: inline-flex;
        min-width: 51px;
        font-size: 16px;
        line-height: calc(16 / 16);

        @media (min-width: 576px) {
            font-size: 12px;
            line-height: calc(18 / 12);
        }
    }

    &__img {
        margin-right: 10px;

        @media (min-width: 576px) {
            margin-right: 5px;
            width: 21px;
            height: auto;
        }
    }
}
</style>
