<template lang="pug">
	.d-flex.flex-column.project-buttons(:class="{'flex-column-reverse': ((!promoCode && !isPaymentNeurobots) || (isPaymentNeurobots && !isBtnBuyTokens)), 'border-dark' : isDarkTheme}")
		template(v-for="row in rows")
			.d-flex.project-buttons__buttons-wrap.justify-content-between
				template(v-for="(item,index) in row")
					template(v-if="item.modal")
						span(:class="itemClasses(item,index,row)" v-html="item.text" @click="$bvModal.show(item.modal)")
					template(v-if="item.click")
						span(:class="itemClasses(item,index,row)" v-html="item.text" @click="item.click")
					template(v-if="item.to")
						router-link(:class="itemClasses(item,index,row)" :to="item.to" target='_blank')
							span(v-html="item.text")
</template>

<script>
export default {
    name: 'ProjectButtons',
    props: {
        promoCode: {
            default: false,
            type: Boolean,
        },
        isDarkTheme: {
            type: Boolean,
            default: false,
        },
        partnerLink: {
            type: [String, null],
            default: null,
        },
        isPaymentNeurobots: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isBtnBuyTokens: true,
        isBuyTokens: false,
    }),
    methods: {
        itemClasses(item, index, row) {
            let ret = ['btn', 'project-buttons__button', 'btn-medium'];
            if (row.length === 1) {
                ret.push('project-buttons__button--full-width mb-0');
            }
            if (row.length === 2) {
                ret.push(index === 1 ? 'project-buttons__button--even' : 'project-buttons__button--odd');
            }

            return ret;
        },
        goToTokenPurchasePage() {
            this.isBuyTokens = true;
            window.scrollTo({ top: 0, behavior: 'instant' });
            this.$emit('currencyRUB', 1);
        },
    },
    watch: {
        isBuyTokens(nV) {
            if (nV == 0) {
                this.$emit('isBuyTokens', false);
            } else if (nV == 1) {
                this.$emit('isBuyTokens', true);
            }
        },
    },
    computed: {
        items() {
            const items = [];
            if (this.promoCode && !this.isPaymentNeurobots) {
                items.push({ text: this.$t('paymentPage.havePromoCode'), modal: 'add-promo-code' });
            }
            if (this.isBtnBuyTokens && this.isPaymentNeurobots) {
                items.push({ text: '💎 ' + this.$t('tokens.buyTokens'), click: this.goToTokenPurchasePage });
            }
            const partnerToTop = !this.promoCode && !this.isMobile;
            if (partnerToTop && this.partnerLink) {
                items.push({ text: this.$t('paymentPage.becomePartner'), to: this.partnerLink });
            }
            if (!this.isPaymentNeurobots) {
                items.push({ text: this.$t('paymentPage.noPromoCode'), to: { name: 'find' } });
            }
            if (this.isPaymentNeurobots) {
                items.push({ text: '😥 ' + this.$t('paymentPage.noAccessToBot'), modal: 'no-access-to-bot' });
            }
            if (!this.isPaymentNeurobots) {
                items.push({ text: '💼 ' + this.$t('paymentPage.manageSubscription'), to: { name: 'subscriber' } });
            }
            if (this.isPaymentNeurobots) {
                items.push({ text: '💼  ' + this.$t('paymentPage.manageSubscription'), modal: 'manage-subscription' });
            }
            items.push({ text: this.$t('paymentPage.complain'), modal: 'complain' });

            if (!partnerToTop && this.partnerLink) {
                items.push({ text: this.$t('paymentPage.becomePartner'), to: this.partnerLink });
            }

            return items;
        },
        rows() {
            const chunkSize = 2;
            let rows = [];
            for (let i = 0; i < this.items.length; i += chunkSize) {
                const chunk = this.items.slice(i, i + chunkSize);
                rows.push(chunk);
            }
            return rows;
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.project-buttons {
    background-color: var(--bg-transparent-black-white-back) !important;
    border-radius: 8px 8px 0 0 !important;
    padding: 20px;

    @include respond-above(xm) {
        padding: 20px 18px 14px;
    }

    &.flex-column-reverse {
        flex-direction: column !important;
    }

    &.border-dark {
        border: 1px solid #444;

        .project-buttons__button {
            border: 1px solid #444 !important;

            &:hover,
            &:active {
                color: white !important;
                background-color: rgba(255, 255, 255, 0.55) !important;
                border: 1px solid transparent !important;
            }
        }
    }

    &__buttons-wrap {
        flex-wrap: wrap;

        @include respond-above(xm) {
            flex-wrap: no-wrap;
            margin: 0 0 16px;
        }

        a {
            &:hover,
            &:active {
                -webkit-text-fill-color: inherit;
                text-decoration: none;
            }
        }

        a:has(.project-buttons__button),
        div:has(.project-buttons__button) {
            width: 100%;
        }

        a:has(.project-buttons__button--full-width),
        div:has(.project-buttons__button--full-width) {
            @include respond-above(xm) {
                max-width: 100% !important;
                min-width: 100% !important;
                margin-top: 16px;
                margin-bottom: -16px;
            }
        }
    }

    &__button {
        color: white !important;
        background-color: var(--bg-project-button) !important;
        border: none !important;
        max-width: 100% !important;
        min-width: 100% !important;
        margin-bottom: 10px;
        padding-left: 7px !important;
        padding-right: 7px !important;

        &:hover,
        &:active {
            color: white !important;
            background-color: rgba(255, 255, 255, 0.55) !important;
        }

        @include respond-above(xm) {
            margin-bottom: 0;
        }

        &--even {
            @include respond-above(xm) {
                max-width: 288px !important;
                min-width: 288px !important;
            }
        }

        &--odd {
            @include respond-above(xm) {
                max-width: 260px !important;
                min-width: 260px !important;
            }
        }

        &--full-width {
            @include respond-above(xm) {
                max-width: 100% !important;
                min-width: 100% !important;
            }
        }
    }
}
</style>
