<template lang="pug">
	.position-relative.card-payment.discount-card(v-if="card" no-body :class="cardClass")
		.d-flex.flex-column.flex-sm-row.justify-content-between.align-items-sm-center
			.d-flex.flex-column(v-if='plan')
				span.b1.overflow-wrap.text-bold.discount-card__subtitle {{ plan.title }}
				.d-flex.align-items-center.mb-xl-0
					span.mr-2.text-bold.discount-card__price.tariff-card__amount(v-html="plan.priceWithDiscountWithCurrency(card.discount)")
					span.b3.mr-2.text-grey.discount-card__price--old(v-html="plan.price.priceWithCurrency()")
					span.b3.text-grey.discount-card__period {{ $t('plan.periods.' + plan.period.title) }}

			.d-none.d-sm-flex.btn.btn-success.btn-medium.discount-card__button(@click="$emit('click', $event)") {{ $t('forms.pay') }}

		p.mb-0.b3.discount-card__desc(v-if="plan.description" v-html="description")
		p.mb-0.b3.discount-card__desc(v-else-if="card.plan && card.plan.description" v-html="description")

		.d-flex
			.discount-card__promo-code
				span.b4.discount-card__title {{ title }}
				span.b4.discount-card__discount -{{ card.discount }}%
			.d-none.d-sm-flex.discount-card__date(v-if='card.isShowPeriod && card.endAt')
				discount-timer(:until='card.endAt' @doneTimer='done')
		
		.d-flex.d-sm-none.btn.btn-success.btn-medium.discount-card__button(@click="$emit('click', $event)") {{ $t('forms.pay') }}

		.d-flex.d-sm-none.discount-card__date(v-if='card.isShowPeriod && card.endAt')
				discount-timer(:until='card.endAt' @doneTimer='done')
</template>

<script>
import DiscountTimer from '@/components/UI/DiscountTimer';

export default {
    name: 'DiscountCard',
    components: {
        DiscountTimer,
    },
    props: {
        card: {
            type: Object,
            default: () => ({}),
        },
        plan: {
            type: Object,
            default: () => ({}),
        },
        title: {
            type: String,
            default: '',
        },
        cardClass: {
            type: String,
            default: '',
        },
    },
    methods: {
        done() {
            this.$emit('done', this.card.id);
        },
        processDescription(description) {
            let desc = description.replace(
                /(https?\:\/\/[^\s]*)/gi,
                '<a class="text-primary" target="_blank" href="$1">$1</a>'
            );

            return desc;
        },
    },
    computed: {
        description() {
            if (this.plan && this.plan.description) {
                return this.processDescription(this.plan.description);
            } else if (this.card.plan && this.card.plan.description) {
                return this.processDescription(this.card.plan.description);
            } else {
                return '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.discount-card {
    padding: 44px 20px 20px;
    background-color: var(--brand-bg-warning);
    margin-bottom: 10px;

    @media (min-width: 576px) {
        padding-top: 20px;
        margin-bottom: 15px;
    }

    &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 320px;
        margin-right: 6px;

        @media (max-width: 1400px) {
            max-width: 270px;
        }

        @media (max-width: 1290px) {
            max-width: 250px;
        }

        @media (max-width: 1250px) {
            max-width: 220px;
        }

        @include respond-below(lg) {
            max-width: 264px;
        }
    }

    &__discount {
        height: max-content;
        padding: 1.5px 6.5px 1.5px;
        color: var(--brand-color-discount);
        border-radius: 6px;
        background-color: var(--brand-color-warning);

        @media (min-width: 576px) {
            padding: 3.5px 6.5px;
        }
    }

    &__price {
        color: var(--brand-color-warning);
        font-size: 24px;
        line-height: 28px;

        &--old {
            text-decoration: line-through;
            margin-top: 6px;
            margin-left: -2px;
        }
    }

    &__period {
        margin-top: 5px;
        margin-left: -3px;
    }

    &__button {
        min-width: 178px;
        margin-top: 20px;
        background-color: var(--brand-color-warning) !important;
        color: var(--foreground-color) !important;

        @media (min-width: 576px) {
            margin-top: 0;
        }

        &.btn:hover {
            background-color: #fc884d !important;
        }

        &.btn:active {
            background: linear-gradient(114deg, #fcb64d 0%, #fc884d 100%) !important;
        }
    }

    &__date {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8.5px 10px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--foreground-color);
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.08);
        margin-top: 11px;

        @media (min-width: 576px) {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        img {
            width: 24px;
            height: 24px;
        }
    }

    &.promotion {
        background-color: var(--brand-bg-promotion);

        .discount-card__discount {
            background: var(--brand-bg-gradient-purple);
        }

        .discount-card__price {
            color: var(--brand-light-gradient-secondary);
        }

        .discount-card__button {
            background-color: var(--brand-light-gradient-secondary) !important;
            background: var(--brand-bg-gradient-purple);

            &:hover {
                background: none;
                background-color: #68259e !important;
            }

            &:active {
                background: linear-gradient(114deg, #a494f8 0%, #da91ff 100%) !important;
            }
        }
    }

    &__desc {
        margin-top: 9px;
        word-wrap: break-word;
        white-space: break-spaces;

        @media (min-width: 576px) {
            margin-top: 14px;
            margin-bottom: 4px !important;
        }
    }

    &__promo-code {
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px 10px 5px;
        border-radius: 8px 8px 0 0;
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.08);
        width: 100%;
        color: var(--grey-text-color);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--foreground-color);

        @media (min-width: 576px) {
            position: static;
            display: block;
            width: auto;
            padding: 6px 10px;
            border-radius: 8px;
            margin-top: 11px;
            margin-right: 10px;
            color: var(--primary-color);
        }
    }

    &__subtitle {
        margin-bottom: 11px;

        @media (min-width: 576px) {
            margin-bottom: 7px;
        }
    }
}
</style>
